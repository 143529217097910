import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { OutlinedButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";

import OfferAllocateClient from "../../services/OfferAllocateClient";

const client = new OfferAllocateClient();

const FoodOfferAllocateView = ({ eHandle }) => {
  const [externalHandle, setExternalHandle] = useState("");
  const [campaignId, setCampaignId] = useState("");

  useEffect(() => {
    if (eHandle) {
      setExternalHandle(eHandle);
    }
  }, [eHandle]);

  const handleExtHandleChange = (e) => {
    setExternalHandle(e.target.value);
  };

  const handleCampaignIdChange = (e) => {
    setCampaignId(e.target.value);
  };

  const isButtonEnabled = () => {
    return !campaignId || !externalHandle;
  };

  const submitZ = () => {
    client
      .allocate({
        externalHandle: eHandle || externalHandle,
        pianoCampaignId: campaignId,
        state: "UNLOADED",
        offerType: "BASE",
      })
      .then(() => toast.success("Done."))
      .catch((e) => {
        toast.error(e?.response?.data?.description);
      });
  };

  return (
    <Card>
      <GridWrapper>
        <GridItem>
          <div className="ln-c-form-group">
            <label htmlFor="text-input-field-1" className="ln-c-label">
              External Handle
            </label>
            <div className="ln-c-input-group">
              <input
                id="text-input-field-1"
                name="externalHandle"
                type="text"
                className="ln-c-text-input ln-c-input-group__control"
                value={externalHandle}
                onChange={handleExtHandleChange}
              />
            </div>
          </div>
        </GridItem>

        <GridItem>
          <div className="ln-c-form-group">
            <label htmlFor="text-input-field-1" className="ln-c-label">
              Piano Campaign Id (UUID)
            </label>
            <div className="ln-c-input-group">
              <input
                id="text-input-field-1"
                name="campaignId"
                type="text"
                className="ln-c-text-input ln-c-input-group__control"
                value={campaignId}
                onChange={handleCampaignIdChange}
              />
            </div>
          </div>
        </GridItem>

        <GridItem>
          <OutlinedButton
            type="button"
            size="small"
            onClick={submitZ}
            disabled={isButtonEnabled()}
          >
            Submit
          </OutlinedButton>
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

export default FoodOfferAllocateView;
