import PropTypes from "prop-types";
import React from "react";
import { Table } from "semantic-ui-react";

class TransactionRow extends React.Component {
  static propTypes = {
    transaction: PropTypes.shape({
      date: PropTypes.string.isRequired,
      location: PropTypes.string,
      transactionType: PropTypes.oneOf(["NORMAL", "BASERATE", "BONUS"])
        .isRequired,
      totalPointsDisplayValue: PropTypes.string.isRequired,
      reason: PropTypes.string,
      additionalInformation: PropTypes.string,
      details: PropTypes.shape({
        campaignName: PropTypes.string.isRequired,
        pointsDisplayValue: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  isCredit = () => {
    const transactionType = this.props.transaction.transactionType;
    const creditTransactions = ["NORMAL", "BASERATE", "BONUS"];
    return creditTransactions.includes(transactionType);
  };

  description() {
    const transactionType = this.props.transaction.transactionType;

    switch (transactionType) {
      case "NORMAL": {
        return "Normal";
      }
      case "BASERATE": {
        return "Baserate";
      }
      case "BONUS": {
        return this.props.transaction.descriptionText;
      }
      default: {
        return "Unknown";
      }
    }
  }

  transactionDetails = () => {
    const details = this.props.transaction.details;
    return details
      .map((detail) => {
        return detail.campaignName + ": " + detail.pointsDisplayValue;
      })
      .join("\n");
  };

  location() {
    const location = this.props.transaction.location;
    return location ? location : "N/A";
  }

  render() {
    return (
      <Table.Row>
        <Table.Cell width="1">{this.props.transaction.date}</Table.Cell>
        <Table.Cell width="3">
          {this.props.transaction.descriptionText}
        </Table.Cell>
        <Table.Cell width="3">{this.location()}</Table.Cell>
        <Table.Cell
          width="1"
          positive={this.isCredit()}
          negative={!this.isCredit()}
        >
          <div style={{ fontSize: "large" }}>
            {this.props.transaction.totalPointsDisplayValue} points
          </div>
          <br />
          <div style={{ fontSize: "small" }}>{this.description()}</div>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default TransactionRow;
