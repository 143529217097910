import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Message } from "semantic-ui-react";

import { CancelButton, FilledButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Search } from "@jsluna/icons";

import { PageHeader } from "../../common/components/PageHeader.jsx";
import { CustomerClient } from "../../services/CustomerClient";
import { LoadingState } from "../../services/http.js";

const customerClient = new CustomerClient();
const cardPrefix = "98263000";
let searchDataArr = [];

const CustomersView = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(undefined);
  const [search, setSearch] = useState("");
  const [emailSearchRequestState, setEmailSearchRequestState] = useState(
    LoadingState.SUCCESS
  );
  const [idSearchRequestState, setIdSearchRequestState] = useState(
    LoadingState.SUCCESS
  );
  const [ehSearchRequestState, setEhSearchRequestState] = useState(
    LoadingState.SUCCESS
  );
  // eslint-disable-next-line
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    const sData = localStorage.getItem("searchHistory");
    const parsedSdata = JSON.parse(sData);
    if (parsedSdata) {
      searchDataArr = parsedSdata;
      setSearchData(searchDataArr);
    }
  }, []);

  const validateSearchFieldForEH = () => {
    let proceedSearch = true;
    const trimmedSearch = search?.trim();
    if (trimmedSearch === undefined || trimmedSearch.length < 3) {
      setError("Please enter valid external handler");
      setIdSearchRequestState(LoadingState.FAILED);
      proceedSearch = false;
    } else {
      const regexp = /^(?=.*[a-zA-Z])(?=.*\d)[0-9a-zA-Z-)]+$/;
      if (!regexp.test(trimmedSearch)) {
        setError("Please enter alpha numeric value");
        setIdSearchRequestState(LoadingState.FAILED);
        proceedSearch = false;
      }
    }
    return proceedSearch;
  };

  const updateSearchDataArr = (data) => {
    const existingData =
      JSON.parse(localStorage.getItem("searchHistory")) || [];
    const isDuplicate = existingData.some(
      (item) => JSON.stringify(item) === JSON.stringify(data)
    );

    if (!isDuplicate) {
      searchDataArr.push(data);
      localStorage.setItem("searchHistory", JSON.stringify(searchDataArr));
    }
  };

  const deleteSearchDataArr = (dataToDelete) => {
    const existingData =
      JSON.parse(localStorage.getItem("searchHistory")) || [];
    const updatedData = existingData.filter(
      (item) => JSON.stringify(item) !== JSON.stringify(dataToDelete)
    );

    localStorage.setItem("searchHistory", JSON.stringify(updatedData));
    searchDataArr = updatedData;
    setSearchData(updatedData);
  };

  const handleChange = (e) => {
    setSearch(e);
  };

  const showSearchDetails = (e) => {
    const regExpEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint-disable-line */

    if (e.length === 11) {
      searchHistoryEmailNectarId(e);
    } else if (regExpEmail.test(e)) {
      searchHistoryEmail(e);
    } else {
      customerClient
        .getCustomerByEH(e)
        .then(({ data }) => {
          setEhSearchRequestState(LoadingState.SUCCESS);
          viewCustomer(data.id);
        })
        .catch((e) => {
          setError(e.response.data.description);
          setEhSearchRequestState(LoadingState.FAILED);
        });
    }
  };

  const searchEmail = () => {
    const regExpEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint-disable-line */
    if (search === undefined || !regExpEmail.test(search)) {
      setError("Please enter valid email id");
      setIdSearchRequestState(LoadingState.FAILED);
      return;
    }

    setError(undefined);
    setEmailSearchRequestState(LoadingState.PENDING);
    customerClient
      .findByEmail(search)
      .then(({ data }) => {
        setEmailSearchRequestState(LoadingState.SUCCESS);
        viewCustomer(data.id);
        updateSearchDataArr(search);
      })
      .catch((e) => {
        setError(e.response.data.description);
        setEmailSearchRequestState(LoadingState.FAILED);
      });
  };

  const searchHistoryEmail = (e) => {
    setError(undefined);
    setEmailSearchRequestState(LoadingState.PENDING);
    customerClient
      .findByEmail(e)
      .then(({ data }) => {
        setEmailSearchRequestState(LoadingState.SUCCESS);
        viewCustomer(data.id);
      })
      .catch((e) => {
        setError(e.response.data.description);
        setEmailSearchRequestState(LoadingState.FAILED);
      });
  };

  const searchNectarId = () => {
    if (search === undefined) {
      setError("Please enter valid CollectorId");
      setIdSearchRequestState(LoadingState.FAILED);
      return;
    }
    let result = search.replace(/\s+/g, "");
    if (result.length < 11 || result.length > 20) {
      setError("CollectorId is last 11 digits of full card number.");
      setIdSearchRequestState(LoadingState.FAILED);
    } else {
      const trimmedDigits = result.length > 11 ? result.slice(-11) : result;

      setError(undefined);
      setIdSearchRequestState(LoadingState.PENDING);
      customerClient
        .findByNectarId(cardPrefix + trimmedDigits)
        .then(({ data }) => {
          setIdSearchRequestState(LoadingState.SUCCESS);
          viewCustomer(data.id);
          updateSearchDataArr(trimmedDigits);
        })
        .catch((e) => {
          setError(e.response.data.description);
          setIdSearchRequestState(LoadingState.FAILED);
        });
    }
  };

  const searchHistoryEmailNectarId = (e) => {
    setError(undefined);
    setIdSearchRequestState(LoadingState.PENDING);
    customerClient
      .findByNectarId(cardPrefix + e)
      .then(({ data }) => {
        setIdSearchRequestState(LoadingState.SUCCESS);
        viewCustomer(data.id);
      })
      .catch((e) => {
        setError(e.response.data.description);
        setIdSearchRequestState(LoadingState.FAILED);
      });
  };

  const viewCustomer = (id) => {
    navigate(`/customer/${id}`);
  };

  const searchEH = () => {
    if (!validateSearchFieldForEH()) {
      return;
    }

    setError(undefined);
    setEhSearchRequestState(LoadingState.PENDING);
    customerClient
      .getCustomerByEH(search.trim())
      .then(({ data }) => {
        setEhSearchRequestState(LoadingState.SUCCESS);
        viewCustomer(data.id);
        updateSearchDataArr(search.trim());
      })
      .catch((e) => {
        setError(e.response.data.description);
        setEhSearchRequestState(LoadingState.FAILED);
      });
  };

  return (
    <>
      <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
        <GridWrapper>
          <GridItem size="1/1">
            <PageHeader>
              <Search />
              Customers
            </PageHeader>
          </GridItem>
          <GridItem>
            <Form error={!!error}>
              {error && <Message error content={error} />}
              <Form.Input
                style={{
                  margin: "1rem 0 2rem 0",
                  width: "100%",
                  backgroundColor: "transparent",
                  boxShadow: "0",
                  fontFamily: "sans-serif",
                  border: "1px solid #737373",
                  borderRadius: "4.571px",
                  color: "#737373",
                  minHeight: "48px",
                }}
                placeholder="Search Customer by email/collector id/external handle"
                value={search || ""}
                onChange={(event) => handleChange(event.target.value)}
              />
              <FilledButton
                disabled={emailSearchRequestState === LoadingState.PENDING}
                onClick={searchEmail}
              >
                Search Email
              </FilledButton>
              &nbsp;&nbsp; &nbsp;&nbsp;
              <FilledButton
                disabled={idSearchRequestState === LoadingState.PENDING}
                onClick={searchNectarId}
              >
                Search Collector Id
              </FilledButton>
              &nbsp;&nbsp; &nbsp;&nbsp;
              <FilledButton
                disabled={ehSearchRequestState === LoadingState.PENDING}
                onClick={searchEH}
              >
                Search External Handler
              </FilledButton>
            </Form>
          </GridItem>
        </GridWrapper>
      </Card>
      <Card>
        <GridWrapper>
          <GridItem>
            <Label htmlFor="">Customer Search History:</Label>
          </GridItem>
          <GridItem>
            {searchDataArr.length > 0 ? (
              <div className="ln-c-table-container">
                <table className="ln-c-table">
                  <thead className="ln-c-table__header">
                    <tr className="ln-c-table__row ln-c-table__header-row">
                      <th className="ln-c-table__header-cell customCol">
                        Customer Id
                      </th>
                      <th className="ln-c-table__header-cell customCol">
                        Links
                      </th>
                      <th className="ln-c-table__header-cell customCol">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ln-c-table__body">
                    {searchDataArr.map((sData) => (
                      <tr className="ln-c-table__row" key={sData}>
                        <td className="ln-c-table__cell">{sData}</td>
                        <td className="ln-c-table__cell">
                          <a
                            href
                            style={{ cursor: "Pointer" }}
                            className="ln-c-link ln-c-link--alpha"
                            onClick={() => showSearchDetails(sData)}
                          >
                            See Details
                          </a>
                        </td>
                        <td className="ln-c-table__cell">
                          <CancelButton
                            onClick={() => deleteSearchDataArr(sData)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Label htmlFor="">
                No Customer Search History Available in the current session
              </Label>
            )}
          </GridItem>
        </GridWrapper>
      </Card>
    </>
  );
};

export default CustomersView;
